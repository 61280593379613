import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTachometerAlt, faCalendarAlt, faCog } from '@fortawesome/free-solid-svg-icons';
import './VehicleSearch.css';

const VehicleSearch = ({ searchType }) => {
  const [vehicles, setVehicles] = useState([]);
  const [filters, setFilters] = useState({
    make: '',
    model: '',
    cabType: '',
    wheelbase: '',
    derivative: '',
    price: '',
    year: '',
    mileage: '',
    gearbox: '',
    bodyType: '',
    colour: '',
    doors: '',
    seats: '',
    fuelType: '',
    batteryRange: '',
    chargingTime: '',
    engineSize: '',
    enginePower: '',
    acceleration: '',
    fuelConsumption: '',
    co2Emissions: '',
    taxPerYear: '',
    insuranceGroup: '',
    driveType: '',
    vatStatus: '',
  });
  const [sortOrder, setSortOrder] = useState('relevance');

  // Distinct options for each field (fetched from the server).
  const [distinct, setDistinct] = useState({
    make: [],
    model: [],
    cabType: [],
    wheelbase: [],
    derivative: [],
    price: [],
    year: [],
    mileage: [],
    gearbox: [],
    bodyType: [],
    colour: [],
    doors: [],
    seats: [],
    fuelType: [],
    batteryRange: [],
    chargingTime: [],
    engineSize: [],
    enginePower: [],
    acceleration: [],
    fuelConsumption: [],
    co2Emissions: [],
    taxPerYear: [],
    insuranceGroup: [],
    driveType: [],
    vatStatus: [],
  });

  useEffect(() => {
    const fetchDistinctFields = async () => {
      try {
        // currently you do:
        // `https://vanir.co.uk/api/vehicles/distinct/${searchType}`
        // but we also need to pass the user-chosen filter values as query params
        const queryString = Object.keys(filters)
          .filter((key) => filters[key])
          .map(
            (key) =>
              `${encodeURIComponent(key)}=${encodeURIComponent(filters[key])}`
          )
          .join('&');
  
        const response = await axios.get(
          `https://vanir.co.uk/api/vehicles/distinct/${searchType}?${queryString}`
        );
        if (response.data) {
          setDistinct(response.data);
        }
      } catch (error) {
        console.error('Error fetching distinct fields:', error);
      }
    };
  
    fetchDistinctFields();
  }, [searchType, filters]);

  // 2) Fetch vehicles based on filters & sort.
  useEffect(() => {
    const fetchVehicles = async () => {
      try {
        const queryString = Object.keys(filters)
          .filter((key) => filters[key]) // Only include filters with a value
          .map(
            (key) =>
              `${encodeURIComponent(key)}=${encodeURIComponent(filters[key])}`
          )
          .join('&');

        // Append sort order:
        const sortParam = sortOrder ? `&sort=${sortOrder}` : '';

        const response = await axios.get(
          `https://vanir.co.uk/api/vehicles/search/${searchType}?${queryString}${sortParam}`
        );
        console.log('API Response:', response.data);
        setVehicles(response.data);
      } catch (error) {
        console.error('Error fetching vehicles:', error);
      }
    };
    fetchVehicles();
  }, [filters, searchType, sortOrder]);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({ ...filters, [name]: value });
  };

  const handleSortChange = (e) => {
    setSortOrder(e.target.value);
  };

  // Helper function: conditionally show a field if
  // the distinct array for that field is non-empty.
  const hasValues = (field) => {
    return distinct[field] && distinct[field].length > 0;
  };

  // Helper to show only for van/truck search
  const isVanOrTruck = (searchType === 'van' || searchType === 'truck');

  return (
    <div className="vehicle-search">

      {/* FILTER PANEL */}
      <div className="filter-panel">
        <h2>Filter and sort</h2>

        {/* Sort dropdown */}
        <div className="sort-section">
          <label htmlFor="sortOrder">Sort by:</label>
          <select id="sortOrder" name="sortOrder" onChange={handleSortChange} value={sortOrder}>
            <option value="relevance">Relevance</option>
            <option value="price_asc">Price (low to high)</option>
            <option value="price_desc">Price (high to low)</option>
            <option value="distance">Distance</option>
            <option value="mileage">Mileage</option>
            <option value="year_desc">Age (newest)</option>
            <option value="year_asc">Age (oldest)</option>
            <option value="most_recent">Most recent</option>
          </select>
        </div>

        <div className="filters">
          {/* Make */}
          {hasValues('make') && (
            <div className="filter-field">
              <label>Make</label>
              <select name="make" onChange={handleFilterChange}>
                <option value="">Any</option>
                {distinct.make.map((m) => (
                  <option key={m} value={m}>{m}</option>
                ))}
              </select>
            </div>
          )}

          {/* Model */}
          {hasValues('model') && (
            <div className="filter-field">
              <label>Model</label>
              <select name="model" onChange={handleFilterChange}>
                <option value="">Any</option>
                {distinct.model.map((mod) => (
                  <option key={mod} value={mod}>{mod}</option>
                ))}
              </select>
            </div>
          )}

          {/* Cab type - only for van/truck and if we have values */}
          {isVanOrTruck && hasValues('cabType') && (
            <div className="filter-field">
              <label>Cab Type</label>
              <select name="cabType" onChange={handleFilterChange}>
                <option value="">Any</option>
                {distinct.cabType.map((c) => (
                  <option key={c} value={c}>{c}</option>
                ))}
              </select>
            </div>
          )}

          {/* Wheelbase - only for van/truck and if we have values */}
          {isVanOrTruck && hasValues('wheelbase') && (
            <div className="filter-field">
              <label>Wheelbase</label>
              <select name="wheelbase" onChange={handleFilterChange}>
                <option value="">Any</option>
                {distinct.wheelbase.map((wb) => (
                  <option key={wb} value={wb}>{wb}</option>
                ))}
              </select>
            </div>
          )}

          {/* Derivative */}
          {hasValues('derivative') && (
            <div className="filter-field">
              <label>Derivative</label>
              <select name="derivative" onChange={handleFilterChange}>
                <option value="">Any</option>
                {distinct.derivative.map((d) => (
                  <option key={d} value={d}>{d}</option>
                ))}
              </select>
            </div>
          )}

          {/* Price */}
          {hasValues('price') && (
            <div className="filter-field">
              <label>Price</label>
              <select name="price" onChange={handleFilterChange}>
                <option value="">Any</option>
                {distinct.price.map((p) => (
                  <option key={p} value={p}>{p}</option>
                ))}
              </select>
            </div>
          )}

          {/* Year */}
          {hasValues('year') && (
            <div className="filter-field">
              <label>Year</label>
              <select name="year" onChange={handleFilterChange}>
                <option value="">Any</option>
                {distinct.year.map((y) => (
                  <option key={y} value={y}>{y}</option>
                ))}
              </select>
            </div>
          )}

          {/* Mileage */}
          {hasValues('mileage') && (
            <div className="filter-field">
              <label>Mileage</label>
              <select name="mileage" onChange={handleFilterChange}>
                <option value="">Any</option>
                {distinct.mileage.map((m) => (
                  <option key={m} value={m}>{m}</option>
                ))}
              </select>
            </div>
          )}

          {/* Gearbox */}
          {hasValues('gearbox') && (
            <div className="filter-field">
              <label>Gearbox</label>
              <select name="gearbox" onChange={handleFilterChange}>
                <option value="">Any</option>
                {distinct.gearbox.map((g) => (
                  <option key={g} value={g}>{g}</option>
                ))}
              </select>
            </div>
          )}

          {/* Body type */}
          {hasValues('bodyType') && (
            <div className="filter-field">
              <label>Body type</label>
              <select name="bodyType" onChange={handleFilterChange}>
                <option value="">Any</option>
                {distinct.bodyType.map((b) => (
                  <option key={b} value={b}>{b}</option>
                ))}
              </select>
            </div>
          )}

          {/* Colour */}
          {hasValues('colour') && (
            <div className="filter-field">
              <label>Colour</label>
              <select name="colour" onChange={handleFilterChange}>
                <option value="">Any</option>
                {distinct.colour.map((c) => (
                  <option key={c} value={c}>{c}</option>
                ))}
              </select>
            </div>
          )}

          {/* Doors */}
          {hasValues('doors') && (
            <div className="filter-field">
              <label>Doors</label>
              <select name="doors" onChange={handleFilterChange}>
                <option value="">Any</option>
                {distinct.doors.map((d) => (
                  <option key={d} value={d}>{d}</option>
                ))}
              </select>
            </div>
          )}

          {/* Seats */}
          {hasValues('seats') && (
            <div className="filter-field">
              <label>Seats</label>
              <select name="seats" onChange={handleFilterChange}>
                <option value="">Any</option>
                {distinct.seats.map((s) => (
                  <option key={s} value={s}>{s}</option>
                ))}
              </select>
            </div>
          )}

          {/* Fuel type */}
          {hasValues('fuelType') && (
            <div className="filter-field">
              <label>Fuel type</label>
              <select name="fuelType" onChange={handleFilterChange}>
                <option value="">Any</option>
                {distinct.fuelType.map((f) => (
                  <option key={f} value={f}>{f}</option>
                ))}
              </select>
            </div>
          )}

          {/* Battery range */}
          {hasValues('batteryRange') && (
            <div className="filter-field">
              <label>Battery range</label>
              <select name="batteryRange" onChange={handleFilterChange}>
                <option value="">Any</option>
                {distinct.batteryRange.map((br) => (
                  <option key={br} value={br}>{br}</option>
                ))}
              </select>
            </div>
          )}

          {/* Charging time */}
          {hasValues('chargingTime') && (
            <div className="filter-field">
              <label>Charging time</label>
              <select name="chargingTime" onChange={handleFilterChange}>
                <option value="">Any</option>
                {distinct.chargingTime.map((ct) => (
                  <option key={ct} value={ct}>{ct}</option>
                ))}
              </select>
            </div>
          )}

          {/* Engine size */}
          {hasValues('engineSize') && (
            <div className="filter-field">
              <label>Engine size</label>
              <select name="engineSize" onChange={handleFilterChange}>
                <option value="">Any</option>
                {distinct.engineSize.map((sz) => (
                  <option key={sz} value={sz}>{sz}</option>
                ))}
              </select>
            </div>
          )}

          {/* Engine power */}
          {hasValues('enginePower') && (
            <div className="filter-field">
              <label>Engine power</label>
              <select name="enginePower" onChange={handleFilterChange}>
                <option value="">Any</option>
                {distinct.enginePower.map((ep) => (
                  <option key={ep} value={ep}>{ep}</option>
                ))}
              </select>
            </div>
          )}

          {/* Acceleration (0-62 mph) */}
          {hasValues('acceleration') && (
            <div className="filter-field">
              <label>Acceleration (0-62 mph)</label>
              <select name="acceleration" onChange={handleFilterChange}>
                <option value="">Any</option>
                {distinct.acceleration.map((acc) => (
                  <option key={acc} value={acc}>{acc}</option>
                ))}
              </select>
            </div>
          )}

          {/* Fuel consumption */}
          {hasValues('fuelConsumption') && (
            <div className="filter-field">
              <label>Fuel consumption</label>
              <select name="fuelConsumption" onChange={handleFilterChange}>
                <option value="">Any</option>
                {distinct.fuelConsumption.map((fc) => (
                  <option key={fc} value={fc}>{fc}</option>
                ))}
              </select>
            </div>
          )}

          {/* CO2 emissions */}
          {hasValues('co2Emissions') && (
            <div className="filter-field">
              <label>CO2 emissions</label>
              <select name="co2Emissions" onChange={handleFilterChange}>
                <option value="">Any</option>
                {distinct.co2Emissions.map((co2) => (
                  <option key={co2} value={co2}>{co2}</option>
                ))}
              </select>
            </div>
          )}

          {/* Tax per year */}
          {hasValues('taxPerYear') && (
            <div className="filter-field">
              <label>Tax per year</label>
              <select name="taxPerYear" onChange={handleFilterChange}>
                <option value="">Any</option>
                {distinct.taxPerYear.map((t) => (
                  <option key={t} value={t}>{t}</option>
                ))}
              </select>
            </div>
          )}

          {/* Insurance group */}
          {hasValues('insuranceGroup') && (
            <div className="filter-field">
              <label>Insurance group</label>
              <select name="insuranceGroup" onChange={handleFilterChange}>
                <option value="">Any</option>
                {distinct.insuranceGroup.map((ig) => (
                  <option key={ig} value={ig}>{ig}</option>
                ))}
              </select>
            </div>
          )}

          {/* Drive type */}
          {hasValues('driveType') && (
            <div className="filter-field">
              <label>Drive type</label>
              <select name="driveType" onChange={handleFilterChange}>
                <option value="">Any</option>
                {distinct.driveType.map((dt) => (
                  <option key={dt} value={dt}>{dt}</option>
                ))}
              </select>
            </div>
          )}

          {/* VAT status - only for van/truck and if we have values */}
          {isVanOrTruck && hasValues('vatStatus') && (
            <div className="filter-field">
              <label>VAT status</label>
              <select name="vatStatus" onChange={handleFilterChange}>
                <option value="">Any</option>
                {distinct.vatStatus.map((v) => (
                  <option key={v} value={v}>{v}</option>
                ))}
              </select>
            </div>
          )}

        </div>
      </div>

        {/* RESULTS */}
        <div className="results">
        {Array.isArray(vehicles) && vehicles.length > 0 ? (
            vehicles.map((vehicle) => {
            // Extract any fields you need
            const imageUrl =
                vehicle?.media?.images?.[0]?.href?.replace('{resize}', 'w800') ||
                'placeholder.jpg';

            // Price & VAT
            const price =
                vehicle?.adverts?.retailAdverts?.totalPrice?.amountGBP ?? 'N/A';
            const vatStatus =
                vehicle?.adverts?.retailAdverts?.vatStatus ?? '';

            // Basic vehicle info
            const make = vehicle?.vehicle?.make ?? '';
            const model = vehicle?.vehicle?.model ?? '';
            const derivative = vehicle?.vehicle?.derivative ?? '';
            const attentionGrabber =
                vehicle?.adverts?.retailAdverts?.attentionGrabber ?? '';

            // Bottom row info
            const mileage = vehicle?.vehicle?.odometerReadingMiles ?? '';
            const year = vehicle?.vehicle?.yearOfManufacture ?? '';
            const gearbox = vehicle?.vehicle?.transmissionType ?? '';

            return (
                <Link
                key={vehicle._id}
                to={`/vehicles/${vehicle._id}`}
                className="vehicle-card"
                >
                {/* TOP IMAGE */}
                <div className="vehicle-card-image">
                    <img
                    src={imageUrl}
                    alt={`${make} ${model}`}
                    />
                </div>

                {/* MAIN CONTENT */}
                <div className="vehicle-card-content">
                    
                    {/* PRICE + VAT */}
                    <div className="card-price-row">
                    <h3 className="price">
                        £{price !== 'N/A' ? Number(price).toLocaleString() : 'N/A'}{' '}
                        {vatStatus && <span className="vat-status">{vatStatus}</span>}
                    </h3>
                    </div>

                    {/* MAKE / MODEL */}
                    <h4 className="make-model">
                    {make} {model}
                    </h4>

                    {/* Derivative */}
                    {derivative && (
                    <p className="derivative">{derivative}</p>
                    )}

                    {/* Attention Grabber */}
                    {attentionGrabber && (
                    <p className="attention-grabber">{attentionGrabber}</p>
                    )}

                    {/* ICONS ROW: MILEAGE | YEAR | GEARBOX */}
                    <div className="card-icons-row">
                    <div className="icon-item">
                        {/* Mileage Icon */}
                        <FontAwesomeIcon icon={faTachometerAlt} />
                        <span>
                        {mileage
                            ? `${Number(mileage).toLocaleString()} miles`
                            : 'N/A'}
                        </span>
                    </div>
                    
                    <div className="icon-item">
                        {/* Year Icon */}
                        <FontAwesomeIcon icon={faCalendarAlt} />
                        <span>{year || 'N/A'}</span>
                    </div>

                    <div className="icon-item">
                        {/* Gearbox Icon */}
                        <FontAwesomeIcon icon={faCog} />
                        <span>{gearbox || 'N/A'}</span>
                    </div>
                    </div>
                </div>
                </Link>
            );
            })
        ) : (
            <p>No vehicles found</p>
        )}
        </div>
    </div>
  );
};

export default VehicleSearch;